import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchList(ctx = null, queryParams = {}) {
      return new Promise((resolve, reject) => {
        axios
          .get('/wsdlr/quotes/list', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchSignatureRequestsByQuoteId(ctx, quoteId) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/wsdlr/quotes/${quoteId}/signature-requests`)
          .then(response => {
            this.currentSignature = response.signatureRequest
            resolve(response)
          }).catch(error => reject(error))
      })
    },
    addQuoteSignatureRequests(ctx, dataParams) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/wsdlr/quotes/signature-requests`, dataParams)
          .then(response => {
            resolve(response)
          }).catch(error => reject(error))
      })
    },
    deleteQuoteSignatureRequests(ctx, dataParams) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/wsdlr/quotes/signature-requests/delete`, dataParams)
          .then(response => {
            resolve(response)
          }).catch(error => reject(error))
      })
    },
    fetchProductListByQuotesId(ctx = null, id) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/wsdlr/quotes/${id}/products`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    loadPreventivo(ctx = null, id) {
      return new Promise((resolve, reject) => {
        axios.get(`/wsdlr/quotes/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    calcolaPreventivo(ctx = null, quotation) {
      return new Promise((resolve, reject) => {
        axios.post('/wsdlr/quotes/addupdate', { obj: quotation })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    confermaPreventivo(ctx, obj) {
      return new Promise((resolve, reject) => {
        axios.post('/wsdlr/quotes/confirm', obj)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    settaPreventivoProntoPerAttivazione(ctx, obj) {
      return new Promise((resolve, reject) => {
        axios.post('/wsdlr/quotes/set-quote-ready-for-activation', obj)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    delete(ctx, obj) {
      return new Promise((resolve, reject) => {
        axios
          .post('/wsdlr/zone/delete', obj) // {id:id}
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchDynamicFileListbyQuotation(ctx, { Id, userData }) {
      return new Promise((resolve, reject) => {
        axios
          .post('/wsdlr/quotes/list-template-by-quotation',
            { Id, userData })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    downloadRenderFile(ctx, { query, reportCode }) {
      return new Promise((resolve, reject) => {
        axios
          .post('/wsdlr/docs/render', {
            Id: query,
            ReportCode: reportCode,
            Type: "PDF",
            responseType: 'arraybuffer',
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

  },
}
