<template>
  <b-overlay
    :show="showLoader"
    rounded="sm"
  >

    <b-card v-if="quotation.Id">
      <h2>Firma documenti</h2>
      <p>Per creare una richiesta di firma cliccare su "Nuova" e quindi su "Avvia"</p>
      <!--  <b-row>
        <b-col
          md="12"
        >
          <b-button
            class="mb-2"
            variant="primary"
            @click="createNewSignatureSign()"
          >
            Nuova richiesta
          </b-button>
        </b-col>
      </b-row> -->

      <!-- v-if="!quoteSignatureRequests || quoteSignatureRequests.length>0" -->
      <b-row>
        <b-col md="1">
          <b-button
            class="btn-sm"
            variant="primary"
            @click="createNewSignatureSign()"
          >
            <feather-icon
              icon="PenToolIcon"
              class="mr-25"
            />
            Nuova
          </b-button>
        </b-col>
        <b-col md="1"><b>Data</b></b-col>
        <b-col md="7"><b>Firmatario</b></b-col>
        <b-col
          lg="2"
          md="3"
        >&nbsp;</b-col>
        <b-col cols="12"><hr></b-col>
      </b-row>

      <!-- Messaggio nessuna richiesta-->
      <b-row
        v-if="!quoteSignatureRequests || quoteSignatureRequests.length==0"
      >
        <b-col>
          <h5>Non è presente nessuna richiesta di firma.</h5>
        </b-col>
        <b-col cols="12">
          <hr>
        </b-col>
      </b-row>
      <!-- Messaggio nessuna richiesta-->

      <b-row
        v-for="quoteSignatureRequest in quoteSignatureRequests"
        :key="quoteSignatureRequest.Id"
        ref="row"
      >
        <b-col md="1">
          <b-badge
            v-if="quoteSignatureRequest.signatureRequest"
            :variant="quoteSignatureRequest.signatureRequest.initStatus!='I' ? getInitStatusDef(quoteSignatureRequest.signatureRequest.initStatus).badgeColor : getSignatureStatusDef(quoteSignatureRequest.signatureRequest.signStatus).badgeColor"
            pill
            class="badge-round"
          >
            {{ quoteSignatureRequest.signatureRequest.initStatus=="I" ? getSignatureStatusDef(quoteSignatureRequest.signatureRequest.signStatus).description : getInitStatusDef(quoteSignatureRequest.signatureRequest.initStatus).description }}
          </b-badge>
        </b-col>

        <!-- Data -->
        <b-col md="1">
          <span v-if="quoteSignatureRequest.signatureRequest">
            {{ (new Date(quoteSignatureRequest.signatureRequest.createDate)).toLocaleDateString() }}
            <b>{{ (new Date(quoteSignatureRequest.signatureRequest.createDate)).toLocaleTimeString("it-IT", {hour: '2-digit', minute:'2-digit'}) }}</b>
          </span>
        </b-col>

        <b-col md="7">
          <b-list-group
            v-if="quoteSignatureRequest.signatureRequest"
          >
            <b-list-group-item
              v-for="signer in quoteSignatureRequest.signatureRequest.signatureRequestSigners"
              :key="signer.Id"
              class="d-fle align-items-left"
            >
              <b-badge
                v-if="signer.signStatus"
                :variant="quoteSignatureRequest.signatureRequest.initStatus!='I' ? 'warning' : getSignerStatusDef(signer.signStatus).badgeColor"
                pill
                class="badge-round"
              >
                {{ getSignerStatusDef(signer.signStatus).description }} <span class="small">({{ signer.signEvent }})</span>
              </b-badge>
              <span>
                {{ signer.firstName }} {{ signer.lastName }} - {{ signer.email }} - Telefono: {{ signer.phoneNumber }}
                <small>({{ signer.signatureAuthenticationMode }})</small>
              </span>

              <b-link
                v-if="quoteSignatureRequest.signatureRequest.initStatus!='I'"
                :variant="signer.initError?'danger':'secondary'"
                class="align-middle ml-50"
                @click="editSigner(signer)"
              >
                <feather-icon
                  :class="signer.initError?'text-danger':'text-secondary'"
                  icon="EditIcon"
                />
              </b-link>

              <b-link
                v-if="loggedUser.role == 'admin' && quoteSignatureRequest.signatureRequest.initStatus=='I' && quoteSignatureRequest.signatureRequest.signStatus!='done'"
                :id="`openSignUrl${signer.Id}`"
                :variant="signer.initError?'danger':'secondary'"
                class="align-middle ml-50"
                @click="openUrl(signer.SignatureLink)"
              >
                <feather-icon
                  icon="ExternalLinkIcon"
                />
              </b-link>
              <b-tooltip
                :target="`openSignUrl${signer.Id}`"
                triggers="hover"
              >
                Apri il link di firma in una nuova finestra
              </b-tooltip>

              <b-link
                v-if="loggedUser.role == 'admin' && quoteSignatureRequest.signatureRequest.initStatus=='I' && quoteSignatureRequest.signatureRequest.signStatus!='done'"
                :id="`copySignUrl${signer.Id}`"
                :variant="signer.initError?'danger':'secondary'"
                class="align-middle ml-50"
                @click="copySignerLinkToClipboard(signer.SignatureLink)"
              >
                <feather-icon
                  icon="CopyIcon"
                />
              </b-link>
              <b-tooltip
                :target="`copySignUrl${signer.Id}`"
              >
                Copia il link della firma
              </b-tooltip>

            </b-list-group-item>
          </b-list-group>
        </b-col>

        <!-- Action Button -->
        <b-col
          lg="2"
          md="3"
          class="mb-50"
        >
          <b-button
            v-if="quoteSignatureRequest.signatureRequest && quoteSignatureRequest.signatureRequest.initStatus!='I'"
            v-ripple.400="'rgba(234, 84, 85, 0.15)'"
            :variant="`${quoteSignatureRequest.signatureRequest.initStatus=='N'?'outline-primary':'outline-warning'}`"
            class="mt-0 mr-1"
            @click="iniziateSignatureRequest(quoteSignatureRequest.signatureRequest.Id, quotation.Id)"
          >
            <feather-icon
              icon="EditIcon"
              class="mr-25"
            />
            <span
              v-if="quoteSignatureRequest.signatureRequest.initStatus=='N'"
            >Avvia</span>
            <span v-else>
              Riprova
            </span>
          </b-button>

          <b-button
            v-if="quoteSignatureRequest.signatureRequest && quoteSignatureRequest.signatureRequest.initStatus=='N'"
            v-ripple.400="'rgba(234, 84, 85, 0.15)'"
            variant="outline-danger"
            class="mt-0"
            @click="deleteSignatureRequest(quoteSignatureRequest.id, quoteSignatureRequest.signatureRequest.Id)"
          >
            <feather-icon
              icon="XIcon"
              class="mr-25"
            />
            <span>Elimina</span>
          </b-button>

          <b-button
            v-if="quoteSignatureRequest.signatureRequest && quoteSignatureRequest.signatureRequest.signStatus=='done'"
            v-ripple.400="'rgba(234, 84, 85, 0.15)'"
            variant="outline-success"
            class="mt-0 mr-1"
            @click="downloadDocument(quoteSignatureRequest.signatureRequest.ExternalId, quoteSignatureRequest.signatureRequest.DocumentId)"
          >
            <feather-icon
              icon="FileIcon"
              class="mr-25"
            />
            <span>Doc.</span>
          </b-button>

          <b-button
            v-if="quoteSignatureRequest.signatureRequest && quoteSignatureRequest.signatureRequest.signStatus=='done'"
            v-ripple.400="'rgba(234, 84, 85, 0.15)'"
            variant="outline-success"
            class="mt-0"
            @click="downloadAuditTrails(quoteSignatureRequest.signatureRequest.ExternalId)"
          >
            <feather-icon
              icon="FileTextIcon"
              class="mr-25"
            />
            <span>Audit</span>
          </b-button>

        </b-col>
        <b-col cols="12">
          <hr>
        </b-col>

      </b-row>

    </b-card>

    <b-modal
      id="signerEdit"
      ref="signerEdit"
      title="Modifica firmatario"
      hide-footer
      no-close-on-backdrop
    >
      <validation-observer
        #default="{ handleSubmit }"
        ref="signerRules"
      >
        <b-form @submit.prevent="handleSubmit(saveSigner())">
          <b-form-group
            label="Cognome"
            label-for="last-name"
          >
            <validation-provider
              #default="{ errors }"
              name="Cognome/Rag.Sociale"
              rules="required"
            >
              <b-form-input
                id="last-name"
                v-model="toEditSigner.lastName"
                placeholder=""
                :state="errors.length > 0 ? false : null"
              />
              <small
                v-if="errors[0]"
                class="text-danger"
              >*Campo obbligatorio</small>
            </validation-provider>
          </b-form-group>

          <b-form-group
            label="Nome"
            label-for="first-name"
          >
            <validation-provider
              #default="{ errors }"
              name="Nome"
              rules="required"
            >
              <b-form-input
                id="last-name"
                v-model="toEditSigner.firstName"
                placeholder=""
                :state="errors.length > 0 ? false : null"
              />
              <small
                v-if="errors[0]"
                class="text-danger"
              >*Campo obbligatorio</small>
            </validation-provider>
          </b-form-group>

          <b-form-group
            label="Email"
            label-for="email"
          >
            <validation-provider
              #default="{ errors }"
              name="Email"
              rules="required"
            >
              <b-form-input
                id="email"
                v-model="toEditSigner.email"
                placeholder=""
                :state="errors.length > 0 ? false : null"
              />
              <small
                v-if="errors[0]"
                class="text-danger"
              >*Campo obbligatorio</small>
            </validation-provider>
          </b-form-group>

          <b-form-group
            label="Telefono mobile"
            label-for="mobile-phone"
          >
            <validation-provider
              #default="{ errors }"
              name="Telefono mobile"
              ruled="required"
            >
              <b-form-input
                id="mobile-phone"
                v-model="toEditSigner.phoneNumber"
                placeholder=""
                :state="errors.length > 0 ? false : null"
              />
              <small
                v-if="errors[0]"
                class="text-danger"
              >*Campo obbligatorio</small>
            </validation-provider>
          </b-form-group>

          <b-form-group
            label="Mosdalità invio OTP"
            label-for="mobile-phone"
          >
            <validation-provider
              #default="{ errors }"
              name="Telefono mobile"
              ruled="required"
            >
              <v-select
                id="authentication-mode"
                v-model="toEditSigner.signatureAuthenticationMode"
                :options="signatureAuthenticationModeDef"
                :reduce="val => val.value"
              />
              <small
                v-if="errors[0]"
                class="text-danger"
              >*Campo obbligatorio</small>
            </validation-provider>
          </b-form-group>

          <!-- submit and reset -->
          <b-col md="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="primary"
              class="mr-1"
            >
              Salva
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="reset"
              variant="outline-secondary"
              @click="chiudiSignerEdit"
            >
              Chiudi
            </b-button>
          </b-col>

        </b-form>
      </validation-observer>
    </b-modal>

  </b-overlay>
</template>

<script >
import {
  BRow,
  BCol,
  BButton,
  BCard,
  BListGroup,
  BListGroupItem,
  BBadge,
  BOverlay,
  VBModal,
  BLink,
  BFormGroup,
  BForm,
  BFormInput,
  BTooltip,
  // BFormTextarea,
  // BTable,
  // BFormInvalidFeedback,
  // BFormCheckbox,
  // BInputGroup,
} from 'bootstrap-vue'
import { ref, onUnmounted, onMounted } from '@vue/composition-api'
import { getUserData } from '@/auth/utils'
import vSelect from 'vue-select'
import store from '@/store'
import Ripple from "vue-ripple-directive"

import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import {
  ValidationProvider,
  ValidationObserver,
  validate,
  extend,
} from "vee-validate"
import { email, required } from '@validations'

import quotationStoreModule from "./quotesStoreModule"
import signatureRequestStoreModule from "./signatureRequestsStore"

export default
{
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  components: {
    BRow,
    BCol,
    BButton,
    BCard,
    BListGroup,
    BListGroupItem,
    BBadge,
    BOverlay,
    BLink,
    ValidationProvider,
    ValidationObserver,
    BFormGroup,
    BForm,
    BFormInput,
    vSelect,
    BTooltip,
    // BTable,
    // BOverlay,
    // BFormTextarea,
    // BFormInvalidFeedback,
    // BFormCheckbox,
    // BInputGroup,
  },

  props: {
    quotation: {
      type: Object,
      default: () => {},
    },
    signFiles: {
      type: Array,
      default: () => {},
    },
  },
  data() {
    return {
      selected: [],
      tableColumns: [
        { key: 'Richiesta', label: 'Richiesta di firma', sortable: true },
        { key: 'Firmatario', label: 'Firmatario', sortable: true },
        { key: 'Stato', label: 'Stato', sortable: true },
        { key: 'actions', label: 'Azioni' },
      ],
    }
  },
  /* SOSTITUITO DALLA MODIFICA DELLA CHIAMATA NEL WIZARD IN MODO CHE NEL SETUP ARRIVI GIA' IL VALORE VALIDO
  watch: {
    quotation: {
      handler(val, oldVal) {
        store.dispatch(`app-wizard/fetchSignatureRequestsByQuoteId`, val.Id).then(response => {
          // console.log('resp.data.quoteSignatureRequests', response.data)
          this.quoteSignatureRequests = response.data.quoteSignatureRequests
        }).catch(err => {
          console.error("fetchSignatureRequestsByQuoteId", err.message)
        })
      },
    },
  },
  */
  setup(props, context) {

    const { refs, root } = context
    const swal = root.$swal

    const loggedUser = getUserData()

    const STORE_MODULE_NAME = "app-wizard"
    const APP_SIGN_MODULE_NAME = 'app-sign'

    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, quotationStoreModule)
    if (!store.hasModule(APP_SIGN_MODULE_NAME)) store.registerModule(APP_SIGN_MODULE_NAME, signatureRequestStoreModule)

    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
      if (store.hasModule(APP_SIGN_MODULE_NAME)) store.unregisterModule(APP_SIGN_MODULE_NAME)
    })

    const toast = useToast()
    const quoteSignatureRequests = ref([])
    const toEditSigner = ref()

    const signatureAuthenticationModeDef = [
      {
        label: "SMS",
        subtitle: "Invio OTP tramite SMS",
        value: "otp_sms",
      },
      {
        label: "Email",
        subtitle: "Invio OTP tramite Email",
        value: "otp_email",
      },

    ]

    const signatureStatusdef = {
      draft: {
        description: 'Bozza',
        badgeColor: 'primary',
      },
      approval: {
        description: 'In approvazione',
        badgeColor: 'primary',
      },
      rejected: {
        description: 'Rifiutata dall\'approvatore',
        badgeColor: 'warning',
      },
      ongoing: {
        description: 'In corso',
        badgeColor: 'info',
      },
      declined: {
        description: 'Rifiutata dal firmatario',
        badgeColor: 'warning',
      },
      expired: {
        description: 'Scaduta',
        badgeColor: 'warning',
      },
      deleted: {
        description: 'Sopspesa',
        badgeColor: 'danger',
      },
      canceled: {
        description: 'Cancellata',
        badgeColor: 'danger',
      },
      done: {
        description: 'Completata',
        badgeColor: 'success',
      },
    }
    const getSignatureStatusDef = signStatus => (signatureStatusdef[signStatus] ? signatureStatusdef[signStatus] : { badgeColor: 'default', description: '--' })

    const signerStatusdef = {
      initiated: {
        description: 'Aggiunto alla richiesta',
        badgeColor: 'primary',
      },
      notified: {
        description: 'Notifica inviata',
        badgeColor: 'primary',
      },
      verified: {
        description: 'Verificato',
        badgeColor: 'warning',
      },
      consent_given: {
        description: 'Identità verificata',
        badgeColor: 'info',
      },
      processing: {
        description: 'Firma in corso',
        badgeColor: 'primary',
      },
      declined: {
        description: 'Firma rifiutata',
        badgeColor: 'warning',
      },
      signed: {
        description: 'Firma effettuata',
        badgeColor: 'success',
      },
      aborted: {
        description: 'Firma abortita',
        badgeColor: 'warning',
      },
      error: {
        description: 'Errore del servizio',
        badgeColor: 'danger',
      },
    }
    const getSignerStatusDef = signStatus => (signerStatusdef[signStatus] ? signerStatusdef[signStatus] : { badgeColor: 'default', description: '--' })

    const initStatusdef = {
      N: {
        description: 'Da avviare',
        badgeColor: 'primary',
      },
      C: {
        description: '2-Richiesta creata',
        badgeColor: 'warning',
      },
      S: {
        description: '3-Firmatari associati',
        badgeColor: 'warning',
      },
      F: {
        description: '4-Files caricati',
        badgeColor: 'warning',
      },
      I: {
        description: '5-Processo di firma avviato',
        badgeColor: 'success',
      },
    }
    const getInitStatusDef = signStatus => (initStatusdef[signStatus] ? initStatusdef[signStatus] : { badgeColor: 'default', description: '--' })

    let checkTick = 0
    const showLoader = ref(false)

    const showOperationResult = (fIcon, fTitle, fMessage) => {
      return swal({
        title: fTitle,
        text: fMessage,
        icon: fIcon,
        // showCancelButton: true,
        // confirmButtonText: 'Si, elimina!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })

      /*
      toast({
        component: ToastificationContent,
        props: {
          title: `Errore nell'aggiunta del firmatario della procedura di firma: ${err.message}`,
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
      */
    }

    const checkSignatureSign = () => {
      if (quoteSignatureRequests.value.findIndex(x => x.signatureRequest.signStatus != 'done') > -1) {
        store.dispatch(`app-wizard/fetchSignatureRequestsByQuoteId`, props.quotation.Id).then(response => {
          quoteSignatureRequests.value = response.data.quoteSignatureRequests
          checkTick = setTimeout(() => {
            // Se è presente aò,emo una firma non ancora completata avvio il pooling di controllo
            checkSignatureSign()
          }, 5000)
        }).catch(err => {
          console.error("fetchSignatureRequestsByQuoteId", err.message)
          checkTick = setTimeout(() => {
            // Se è presente aò,emo una firma non ancora completata avvio il pooling di controllo
            checkSignatureSign()
          }, 5000)
        })
      } else {
        checkTick = 0
      }
    }

    // if (props.quotation.Id) { // PERCHE' E' DUPLICATO?
    if (props.quotation.Id) {
      store.dispatch(`app-wizard/fetchSignatureRequestsByQuoteId`, props.quotation.Id).then(response => {
      // store.dispatch(`app-wizard/fetchSignatureRequestsByQuoteId`, props.quotationId).then(response => {
        // console.log('resp.data.quoteSignatureRequests', response.data)
        quoteSignatureRequests.value = response.data.quoteSignatureRequests
        checkSignatureSign()
      }).catch(err => {
        console.error("fetchSignatureRequestsByQuoteId", err.message)
        showOperationResult("error", "Errore", `Errore nel recupero delle richieste di firma: ${err.message}`)
        checkSignatureSign()
      })
    }
    // }

    const createNewSignatureSign = () => {
      const signatureRequest = {
        signers: [],
        signFiles: props.signFiles,
      }
      signatureRequest.signers.push({
        firstName: props.quotation.nome_cliente,
        lastName: props.quotation.cognome_cliente,
        phoneNumber: props.quotation.cellulare.indexOf("+39") !== -1 ? props.quotation.cellulare : `+39${props.quotation.cellulare}`,
        email: props.quotation.email,
      })
      store.dispatch(`app-sign/AddSignatureRequest`, signatureRequest).then(response => {
        // Recupero l'id dalla response ed inserisco la quote signature request
        // console.log(`app-sign/AddSignatureRequest`, response)
        // response.data.signatureRequest.Id

        store.dispatch(`app-wizard/addQuoteSignatureRequests`, { quoteId: props.quotation.Id, signatureRequestId: response.data.signatureRequest.Id }).then(qRes => {
          store.dispatch(`app-wizard/fetchSignatureRequestsByQuoteId`, props.quotation.Id).then(fQRes => {
            // console.log('resp.data.quoteSignatureRequests', response.data)
            quoteSignatureRequests.value = fQRes.data.quoteSignatureRequests
            toast({
              component: ToastificationContent,
              props: {
                title: `Richiesta di firma creata`,
                icon: 'InfoIcon',
                variant: 'success',
              },
            })
          }).catch(err => {
            toast({
              component: ToastificationContent,
              props: {
                title: `Errore nell'aggiornamento delle richieste di firma: ${err.message}`,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })
        }).catch(err => {
          console.error(err)
          showOperationResult("error", "Errore creazione firma", `Errore nell'associazione della richiesta di firma al preventivo: ${err.message}`)
        })
      }).catch(err1 => {
        console.error(err1)
        showOperationResult("error", "Errore creazione firma", `Errore nell'aggiunta del firmatario della procedura di firma: ${err1.message}`)
      })
    }

    const deleteSignatureRequest = (quoteSignatureId, signatureRequestId) => {
      store.dispatch(`app-wizard/deleteQuoteSignatureRequests`, { quoteSignatureId }).then(qRes => {
        store.dispatch(`app-sign/DeleteSignatureRequest`, { signatureRequestId }).then(response => {
          store.dispatch(`app-wizard/fetchSignatureRequestsByQuoteId`, props.quotation.Id).then(fQRes => {
            // console.log('resp.data.quoteSignatureRequests', response.data)
            quoteSignatureRequests.value = fQRes.data.quoteSignatureRequests
            toast({
              component: ToastificationContent,
              props: {
                title: `Richiesta di firma eliminata`,
                icon: 'InfoIcon',
                variant: 'success',
              },
            })
          }).catch(err => {
            toast({
              component: ToastificationContent,
              props: {
                title: `Errore nell'aggiornamento delle richieste di firma: ${err.message}`,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })
        }).catch(err => {
          console.error(err)
          showOperationResult("error", `Errore eliminazione firma`, `Errore nell'eliminazione della richiesta di firma e relativi firmatari: ${err.message}`)
        })
      }).catch(err1 => {
        console.error(err1)
        showOperationResult("error", `Errore eliminazione firma`, `Errore nell'eliminazione dell'associazione della richiesta di firma al preventivo: ${err1.message}`)
      })
    }

    const saveSigner = () => {
      refs.signerRules.validate().then(result => {
        // console.log("vaidationResult", result)
        if (result) {
          store.dispatch(`app-sign/UpdateSigner`, toEditSigner.value).then(response => {
            store.dispatch(`app-wizard/fetchSignatureRequestsByQuoteId`, props.quotation.Id).then(fQRes => {
              quoteSignatureRequests.value = fQRes.data.quoteSignatureRequests
              refs.signerEdit.hide()
              toast({
                component: ToastificationContent,
                props: {
                  title: `Firmatario salvato`,
                  icon: 'InfoIcon',
                  variant: 'success',
                },
              })
            }).catch(err => {
              toast({
                component: ToastificationContent,
                props: {
                  title: `Errore nell'aggiornamento delle richieste di firma: ${err.message}`,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            })
          }).catch(err1 => {
            console.error(err1)
            showOperationResult("error", `Errore salvataggio firmatario`, `Errore nel salvataggio del firmatario: ${err1.message}`)
          })
        }
      })
    }

    const iniziateSignatureRequest = (SignatureRequestId, quoteId) => {
      // InitiateSignatureProcess
      showLoader.value = true
      store.dispatch(`app-sign/InitiateSignatureProcess`, { SignatureRequestId, quoteId }).then(response => {
        showLoader.value = false
        showOperationResult("success", `Avvio processo di firma`, `Avvio del processo di firma effettuato regolarmente`)
        if (checkTick == 0) {
          checkSignatureSign()
        }
      }).catch(err => {
        showLoader.value = false
        console.error(err)
        showOperationResult("error", `Errore avvio firma`, `Errore nell'avvio della procedura di firma: ${err.response.data.message}`)
        if (checkTick == 0) {
          checkSignatureSign()
        }
      })
    }

    const startDownload = (data, fileName) => {
      const binaryString = window.atob(data) // window.atob(res.data)// Buffer.from(res.data, 'base64').toString()//
      const bytes = new Uint8Array(binaryString.length)
      const mappedData = bytes.map((byte, i) => binaryString.charCodeAt(i))
      // const buff = Buffer.from(response.data, 'binary')
      const blob = new Blob([mappedData], { type: 'pdf' })
      const fileURL = window.URL.createObjectURL(blob)
      const fileLink = document.createElement('a')
      fileLink.href = fileURL
      fileLink.setAttribute('download', fileName)// 'trst.pdf')// res.data.Data.file.name)
      document.body.appendChild(fileLink)
      fileLink.click()
      URL.revokeObjectURL(fileURL)
    }

    const downloadDocument = (signatureRequestId, documentId) => {
      store.dispatch(`app-sign/DownloadRequestDocument`, { signatureRequestId, documentId }).then(response => {
        // console.log(response)
        const docName = `docs_${props.quotation.Id}.${response.data.contentType == 'application/zip' ? 'zip' : 'pdf'}`
        startDownload(response.data.payload, docName)
      }).catch(err => {
        console.error("fetchSignatureRequestsByQuoteId", err.message)
        toast({
          component: ToastificationContent,
          props: {
            title: `Errore nel recupero delle richieste di firma: ${err.message}`,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
    }

    const downloadAuditTrails = (signatureRequestId, documentId) => {
      store.dispatch(`app-sign/DownloadSignatureRequestAuditTrails`, { signatureRequestId }).then(response => {
        // console.log(response)
        startDownload(response.data, "audita_trails.pdf")
      }).catch(err => {
        console.error("fetchSignatureRequestsByQuoteId", err.message)
        toast({
          component: ToastificationContent,
          props: {
            title: `Errore nel recupero delle richieste di firma: ${err.message}`,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
    }

    return {
      quoteSignatureRequests,
      checkTick,
      showLoader,
      toEditSigner,
      signatureAuthenticationModeDef,
      createNewSignatureSign,
      checkSignatureSign,
      iniziateSignatureRequest,
      getInitStatusDef,
      getSignatureStatusDef,
      getSignerStatusDef,
      downloadDocument,
      downloadAuditTrails,
      saveSigner,
      deleteSignatureRequest,
      loggedUser,
    }
  },
  methods: {
    editSigner(signer) {
      this.toEditSigner = signer
      this.$bvModal.show("signerEdit")
    },
    chiudiSignerEdit() {
      this.$bvModal.hide("signerEdit")
    },
    async openUrl(myurl) {
      try {
        window.open(myurl, '_blank')
      } catch ($e) {
        // alert('Cannot copy')
      }
    },
    async copySignerLinkToClipboard(mytext) {
      try {
        await navigator.clipboard.writeText(mytext)
      } catch ($e) {
        // alert('Cannot copy')
      }
    },

  },

}
</script>
