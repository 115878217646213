import axios from '@axios'

export default {
  namespaced: true,
  state: {
    currentSignature: {},
  },
  getters: {},
  mutations: {
    nuovaSignature(quotationId, signatureRequest) {
      // mutate state
      // state.count++
    },
  },
  actions: {
    fetchSignature(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/wsdlr/signature-requests/${id}`)
          .then(response => {
            this.currentSignature = response.signatureRequest
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    AddSignatureRequest(ctx, signatureRequest) {
      return new Promise((resolve, reject) => {
        axios
          .post('/wsdlr/signature-requests', { obj: signatureRequest })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    DeleteSignatureRequest(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/wsdlr/signature-requests/delete', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    UpdateSigner(ctx, signer) {
      return new Promise((resolve, reject) => {
        axios
          .post('/wsdlr/signature-requests/signer', { obj: signer })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    InitiateSignatureProcess(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/wsdlr/signature-requests/initiate', { params })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    DownloadRequestDocument(ctx, { signatureRequestId, documentId }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/wsdlr/signature-requests/document/${signatureRequestId}/${documentId}`)
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    DownloadSignatureRequestAuditTrails(ctx, { signatureRequestId }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/wsdlr/signature-requests/audit-trails/${signatureRequestId}`)
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
  },
}
